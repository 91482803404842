<template>
  <div style="width:100%;height:100%;overflow:hidden;">
    <div :id="id+'_tools2'" :style="'width:100%;border-bottom: 1px solid #eee;height:50px;background:#fafafa;z-index:10000;'">
      <Dropdown style="margin: 10px 5px;"  trigger="click">
        <Button type="text" icon="md-photos" >
            图片
            <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
            <DropdownItem><div @click="upImage"><Icon type="md-cloud-upload" /> 
            本地上传
            <input id="addImg"
                     type="file"
                     @change="getFileToPicture"
                     multiple="multiple"
                     accept="image/*"
                     style="display:none;"
                     />
            </div></DropdownItem> 
            <DropdownItem><div @click="editor.getDialog('insertimage').open();"><Icon type="md-photos" /> 从图库中选择</div></DropdownItem>
        </DropdownMenu>
    </Dropdown>

     <Button type="text" icon="md-film"  style="margin-right: 5px;" @click="editor.getDialog('insertvideo').open();">
            视频
        </Button>
   <Button type="text" icon="md-musical-notes"  style="margin-right: 5px;"  @click="editor.getDialog('music').open();">
            音频
        </Button>
   <Button type="text" icon="md-link"  style="margin-right: 5px;"  @click="editor.getDialog('attachment').open();">
            超链接
        </Button>
          <Button type="text" icon="md-navigate"  style="margin-right: 5px;"  @click="editor.getDialog('map').open();">
            地理位置
        </Button>


  <Dropdown style="margin: 10px 5px 0 0;"  trigger="click">
        <Button type="text" icon="ios-grid" >
           模板
            <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
            <DropdownItem><div ><Icon type="md-albums" /> 
            将编辑器内容保存为模板
            </div></DropdownItem> 
            <DropdownItem><div @click="editor.getDialog('template').open();"><Icon type="ios-grid" /> 模板库</div></DropdownItem>
        </DropdownMenu>
    </Dropdown>


  <Dropdown style="margin: 10px 5px 0 0;"  trigger="click">
        <Button type="text" icon="ios-paper" >
           word文档
            <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
            <DropdownItem><div  ><Icon type="md-paper" /> 
            一键上传编辑器中的word图片
            </div></DropdownItem> 
            <DropdownItem><div @click="editor.getDialog('template').open();"><Icon type="ios-paper" /> word文档转换</div></DropdownItem>
        </DropdownMenu>
    </Dropdown>

    </div>
    <script :id="id" type="text/plain"></script>
  </div>
</template>

<script>
import "../../public/ueditor/ueditor.config.js";
import "../../public/ueditor/ueditor.all.js";
import "../../public/ueditor/lang/zh-cn/zh-cn.js";
import "../../public/ueditor/jquery-2.2.3.min.js";

export default {
  name: "Editor",
  data() {
    return {
      id: "editor",
      content: "",
      editor: null,
      config: {
        autoHeightEnabled: false,
        autoClearinitialContent: true, 
        initialFrameWidth: "100%",
        initialFrameHeight: "50%",
        UEDITOR_HOME_URL: "/ueditor/",
      },
    };
  },
  mounted() {
    //初始化UE
    const _this = this;
    var id=this.$core.getQueryString("id");
    if(id!=null) this.id=id;
    this.editor = UE.getEditor(this.id,this.config);
    this.editor.ready(function () {
    _this.$("#"+_this.id+" .edui-editor-toolbarbox").prepend(_this.$("#"+_this.id+"_tools2"));
    _this.resize();
    //加载数据
    _this.editor.setContent(parent.s.getContent());
    window.onresize=_this.resize;
    });
    window.editor=this;
  },
  destoryed() {
    this.editor.destory();
  },
  methods: {
    resize(){
      var ifrmhd=this.$("#"+this.id+" .edui-editor-iframeholder");
      var toolbb=this.$("#"+this.id+" .edui-editor-toolbarbox");
      ifrmhd.css("height","calc(100vh - "+toolbb.height()+"px)");
    },
    //弹出图片上传的对话框
    upImage(){
        this.$("#addImg").click();
       
    },
    getFileToPicture () {
      var that=this;
            var img=that.$("#addImg");
            var files = that.$(img)[0].files[0];
            
            if (files.type.indexOf("image/") != 0) {
                alert("图片格式不支持");
                return;
            }
            if (files.size > 20480000) {
                alert("图片超出2M限制");
               img.val('');
                return;
            }
            window.UE.uploadFile(files,function(data){
                          var link = data.get('host') + '/' + data.get('key');
                          that.editor.execCommand('inserthtml', '<img src="'+link+'"/>');
                          //保存资原库
                          var p={
                            "custGlobalId":parent.vue.custGlobalId,
                            "fileType":"image",
                            "groupId":10000,
                            "fileName":files.name,
                            "fileSize":files.size,
                            "orgCode":parent.vue.loginInfo.userinfo.orgCode,
                            "filePath":link
                            };
                        //    console.log(p);
                          that.$post("/old/api/pc/fileUpload/saveFileUpload", p).then((res) => {
                            //  console.log(res);
                            });
                        },function(data){
                          img.val('');
                       });
        },
    getUEContent: function () {
      return this.editor.getContent();
    },
    getContentTxt: function () {
      return this.editor.getContentTxt();
    }
  },
};
</script>
<style lang="less" scoped>
</style>
